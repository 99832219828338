<template>
	<div class="proofing-container">
		<div class="header acea-row row-between-wrapper" ref="header">
			<div class="black" @click="returnChange()">
				<img src="@assets/images/icon_black.png" alt="" />
			</div>
			<p>共享工厂</p>
		</div>
		<div class="proofing-content fact acea-row row-between">
			<div class="proofing-list">
				<router-link :to="{path:'/shared_factory'}">
					<img src="@assets/images/icon_fact_list1.png">
				</router-link>
			</div>
			<div class="proofing-list">
				<!-- <router-link :to="{path:'/shared_factory'}"> -->
					<img src="@assets/images/icon_fact_list2.png">
				<!-- </router-link> -->
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name:'shareFactoryList',
	data(){
		return {
			typeData:'',
		}
	},
	watch: {
	    $route() {
	      
	    }
	},
	mounted(){

	},
	methods:{
		//返回上一页
		returnChange() {
	      var that = this;
	      that.$router.go(-1);
	    },
	}
}
</script>

<style>
.proofing-container { padding-top:50px; height:100%; }

.proofing-container.router { background:#fff }
.proofing-container .header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 0.96rem;
  font-size: 0.3rem;
  color: #050505;
  background-color: #fff;
  z-index: 11;
  border-bottom: 0.01rem solid #eee;
}
.proofing-container .header .black {
  width: 7px;
  height: 14px;
  margin-left: 10px;
}
.proofing-container .header .black img {
  width: 100%;
  height: 100%;
}
.proofing-container .header p {
  width: calc(100% - 17px);
  text-align: center;
  color: #333;
}
</style>